


























import Vue, {PropType} from "vue"
import TurntablePlayer from "../scripts/TurntablePlayer";
import SlStyleButton from "@/components/UI/SlStyleButton.vue";
import SpeedControl from "@/components/UI/SpeedControl.vue";
import TrackSelect from "@/components/UI/TrackSelect.vue";
import StatusLamp from "@/components/UI/StatusLamp.vue";
import * as Tone from "tone";
import {Gain} from "tone";
import {TrackData} from "@/App.vue";

type ScratchableTrackData = {
  player: TurntablePlayer;
  isInitialized: boolean;
  isMouseWheel: boolean;
  isClicking: boolean;
  isForward: boolean;
  isControlServer: boolean;
  currentServerSpeed: number;
  lastMessageFromServerAt: number;
  mouseWheelNoEventCount: number;
  beforeScrollPos: number;
  mouseMoveDiff: number;
  gain: Tone.Gain;
}

const mouseWheelDisableThreshold = 3;

type TrackNoteNumList = {
  reset: number;
  speed: number;
  start: number;
}

export default Vue.extend({
  components: {SlStyleButton, SpeedControl, TrackSelect, StatusLamp},
  props: {
    audioContext: {
      type: window.AudioContext || window.webkitAudioContext,
      required: true
    },
    trackData: {
      type: Object as PropType<TrackData>,
      required: true
    },
    noteNumList: {
      type: Object as PropType<TrackNoteNumList>,
      default: () => {
        return {reset: Infinity, speed: Infinity, start: Infinity}
      }
    }
  },
  data(): ScratchableTrackData {
    return {
      player: new TurntablePlayer(this.audioContext, this.trackData.buffer),
      isInitialized: false,
      isMouseWheel: false,
      isClicking: false,
      isForward: true,
      isControlServer: false,
      lastMessageFromServerAt: 0.,
      currentServerSpeed: 1.0,
      mouseWheelNoEventCount: 0,
      beforeScrollPos: 0,
      mouseMoveDiff: 0,
      gain: new Tone.Gain<"gain">(1.0)
    }
  },
  mounted() {
    window.addEventListener('mouseup', () => {
      this.isClicking = false;
      this.player.seekEnd();
    }, false);

    window.addEventListener('mousemove', (e: MouseEvent) => {
      this.mousemove(-e.movementY);
    }, false);

    this.initTrack();
  },
  methods: {
    mousemove(movementY: number) {
      if (this.isClicking) {
        this.mouseMoveDiff += movementY;
        // if (this.isControlServer) this.mouseMoveDiff *= 5.0;
        if (this.isControlServer) {
          // (this.$refs.target as InstanceType<typeof Element>).scrollTo(0, (this.$refs.target as InstanceType<typeof Element>).scrollTop - movementY);
        } else {
          (this.$refs.target as InstanceType<typeof Element>).scrollTo(0, (this.$refs.target as InstanceType<typeof Element>).scrollTop - movementY);
        }
      }
    },
    controlFromServer(speed: number) {
      this.isControlServer = true;
      this.currentServerSpeed = speed;

      // if (this.isClicking) this.currentServerSpeed *= 0.5;

      this.lastMessageFromServerAt = this.audioContext.currentTime;

    },
    scrollElement(position: number) {
      if (position <= 0 || position >= (this.$refs.target as InstanceType<typeof Element>).scrollHeight) {
        (this.$refs.target as InstanceType<typeof Element>).scrollTo(0, 0);
        this.player.sendData({type: "position", content: 0});
      } else {
        (this.$refs.target as InstanceType<typeof Element>).scrollTo(0, position);
      }
    },
    init() {
      if (this.isInitialized) return;

      this.$emit("start");

      this.gain = new Gain(1.0);
      Tone.connect(this.player.getProcessor(), this.gain);

      this.isInitialized = true;
    },
    initTrack() {
      (this.$refs.target as InstanceType<typeof Element>).addEventListener('mousewheel', this.mouseWheelEventHandler.bind(this), false);

      (this.$refs.target as InstanceType<typeof HTMLElement>).addEventListener('wheel', this.wheelEventHandler.bind(this));

      (this.$refs.target as InstanceType<typeof Element>).addEventListener('mousedown', this.mouseDownEventHandler.bind(this), false);

      this.player.processEventEmitter.on("position", this.positionEventHandler);
    },
    resetTrack() {
      (this.$refs.target as InstanceType<typeof Element>).removeEventListener('mousewheel', this.mouseWheelEventHandler, false);

      (this.$refs.target as InstanceType<typeof HTMLElement>).removeEventListener('wheel', this.wheelEventHandler);

      (this.$refs.target as InstanceType<typeof Element>).removeEventListener('mousedown', this.mouseDownEventHandler, false);

      this.player.processEventEmitter.off("position", this.positionEventHandler);

      this.player.stopImmediate();

      Tone.disconnect(this.player.getProcessor(), this.gain);
    },
    mouseWheelEventHandler() {
      this.player.seekStart();
      this.isMouseWheel = true;
      this.mouseWheelNoEventCount = 0;
    },
    wheelEventHandler(e: WheelEvent) {
      (this.$refs.target as InstanceType<typeof HTMLElement>).scrollTop = ((this.$refs.target as InstanceType<typeof HTMLElement>).scrollTop + ((e.deltaY) * -1));
      this.player.seekStart();
      this.isMouseWheel = true;
      this.mouseWheelNoEventCount = 0;
      e.preventDefault();
    },
    mouseDownEventHandler() {
      this.isClicking = true;
      this.player.seekStart();
    },
    positionEventHandler(position: number) {
      if (position && !this.isClicking && !this.isMouseWheel) {
        const newPos = position / this.trackData.buffer.sampleRate * this.trackData.scrollScale;

        this.scrollElement(newPos);
      } else if (position && this.isClicking) {
        if (this.isControlServer) {
          const newPos = position / this.trackData.buffer.sampleRate * this.trackData.scrollScale;
          this.scrollElement(newPos - this.mouseMoveDiff);
        } else {
          this.scrollElement((this.$refs.target as InstanceType<typeof Element>).scrollTop - this.mouseMoveDiff);
        }

        this.mouseMoveDiff = 0;
      }
    },
    start() {
      if (!this.isInitialized) this.init();

      if (this.isControlServer) return;

      this.player.start();
      // this.autoScroll = true;
    },
    stop() {
      this.player.stopFade();
      // this.autoScroll = false;
    },
    update(currentTime: number, deltaTime: number) {
      // if (this.player.status === "stopping") return;

      // console.log(this.player.defaultPlaybackRate);

      const currentScrollPos = (this.$refs.target as InstanceType<typeof Element>).scrollTop;

      if (this.isClicking || this.isMouseWheel) {
        if (this.isControlServer) {
          // const playbackRateByScroll = (currentScrollPos - this.beforeScrollPos) / this.scrollScale / (isNaN(deltaTime) || deltaTime === 0 ? 1 : deltaTime);
          const playbackRate = this.currentServerSpeed;
          this.player.playbackRateParam.linearRampToValueAtTime(playbackRate, deltaTime + currentTime);
        } else {
          this.player.playbackRateParam.linearRampToValueAtTime((currentScrollPos - this.beforeScrollPos) / this.trackData.scrollScale / (isNaN(deltaTime) || deltaTime === 0 ? 1 : deltaTime), deltaTime + currentTime);
        }
      } else if (this.isControlServer) {
        this.player.playbackRateParam.linearRampToValueAtTime(this.currentServerSpeed, deltaTime + currentTime);
      }

      this.player.update(currentTime, deltaTime);

      // トラックパッドでスクロールの終わりを検出するためのイベント
      if (this.isMouseWheel) {
        this.mouseWheelNoEventCount++;
        if (this.mouseWheelNoEventCount > mouseWheelDisableThreshold) {
          this.player.seekEnd();
          this.isMouseWheel = false;
        }
      }

      // 再生されていない時にレコードを回すとゆっくり減衰して止まる挙動
      if (this.player.status === "stop" && !this.isControlServer) {
        let newPlayback = this.player.playbackRateParam.value * 0.999;
        // 0付近になったら急激に減衰
        newPlayback *= Math.max(0.0, Math.min(1.0, Math.pow(Math.abs(this.player.playbackRateParam.value), 0.005)))
        this.player.playbackRateParam.linearRampToValueAtTime(newPlayback, deltaTime + currentTime);
      }

      // サーバーからの通信が途絶えた時に無効化する処理 (1秒以上）
      if (this.audioContext.currentTime - this.lastMessageFromServerAt > 1.0) {
        this.currentServerSpeed = 1.0;
        this.isControlServer = false;
      }

      this.beforeScrollPos = currentScrollPos;
    },
    getProcessor(): Tone.Gain {
      return this.gain;
    },
    changeGain(gain: number): void {
      this.gain.gain.setValueAtTime(gain, this.audioContext.currentTime);
      // this.gain.gain.value = gain;
    },
    changePitch(pitch: number): void {
      this.player.defaultPlaybackRate = pitch;
    },
    trackChangeRequested(trackName: string) {
      this.$emit("track-selected", trackName);
    }
  },
  watch: {
    trackData(newTrackData) {
      this.resetTrack();

      this.player = new TurntablePlayer(this.audioContext, newTrackData.buffer);

      this.initTrack();

      Tone.connect(this.player.getProcessor(), this.gain);
    }
  }
})


