













import Vue, {PropType} from "vue";
import {Player, Volume} from "tone";

import SlStyleButton from "@/components/UI/SlStyleButton.vue";
import StatusLamp from "@/components/UI/StatusLamp.vue";

export type LoadedBeat = {
  name: string;
  buffer: AudioBuffer;
  midiNoteNum: Number;
}

type BeatSelectData = {
  player: Player | null;
  currentIndex: number;
}

export default Vue.extend({
  name: "BeatSelect",
  components: {SlStyleButton, StatusLamp},
  props: {
    beats: {
      required: true,
      type: Array as PropType<LoadedBeat[]>
    },
    volume: {
      required: true,
      type: Object as PropType<Volume>
    }
  },
  methods: {
    beatSelect(buffer: AudioBuffer, index: number) {
      // すでにプレイしているものがあれば停止
      this.player?.stop(0);

      this.player = new Player(buffer);
      this.player.loop = true;

      this.player.connect(this.volume);
      this.player.start(0);

      this.currentIndex = index;
    }
  },
  data(): BeatSelectData {
    return {
      player: null,
      currentIndex: -1
    };
  }
});
