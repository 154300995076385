







import Vue from "vue";
import midiManager from "@/scripts/MidiManager";

export default Vue.extend({
  name: "SlStyleButton",
  props: {
    midiNoteNum: {
      type: Number,
      default: Infinity
    },
    midiChannelNum: {
      type: Number,
      default: -1
    },
    isFireOnlyUp: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    midiManager.eventEmitter.on("data", data => {
      if (data[1] === this.midiNoteNum && (this.midiChannelNum < 0 || data[0] == this.midiChannelNum)) {
        if (this.isFireOnlyUp && data[2] < 64) return;
        this.$emit('click', data[2] > 64);
      }
    });
  }
});
