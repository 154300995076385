




import Vue from "vue";

export default Vue.extend({
  name: "StatusLamp",
  props: {
    onColor: {
      default: '#df0006',
      type: String
    },
    offColor: {
      default: '#808080',
      type: String
    },
    isActive: {
      default: false,
      type: Boolean
    }
  }
});
