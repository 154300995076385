import WorkletPlayer from "./WorkletPlayer";

export type TurntablePlayerStatus =
  | "playing"
  | "starting"
  | "stopping"
  | "stop";

export default class TurntablePlayer extends WorkletPlayer {
  startAt: number;
  startingTime: number;
  defaultPlaybackRate: number;
  status: TurntablePlayerStatus;
  isSeeking: boolean;

  constructor(context: AudioContext, audioBuffer: AudioBuffer) {
    super(context, audioBuffer);

    this.status = "stopping";
    this.startAt = 0;
    this.startingTime = 0.6;
    this.defaultPlaybackRate = 1.0;
    this.isSeeking = false;
  }

  start() {
    this.playbackRateParam.linearRampToValueAtTime(
      this.defaultPlaybackRate,
      this.context.currentTime + this.startingTime
    );
    this.status = "starting";
    this.startAt = this.context.currentTime;
  }

  startImmediate() {
    this.status = "playing";
  }

  stopFade() {
    this.playbackRateParam.linearRampToValueAtTime(
      0.0,
      this.context.currentTime + this.startingTime
    );
    this.status = "stopping";
    this.startAt = this.context.currentTime;
  }

  stopImmediate() {
    this.playbackRateParam.setValueAtTime(0.0, this.context.currentTime);
    this.status = "stop";
  }

  update(currentTime: number, deltaTime: number) {
    if (
      this.status === "starting" &&
      this.startAt + this.startingTime < currentTime
    ) {
      this.status = "playing";
    } else if (this.status === "playing" && !this.isSeeking) {
      // reset playbackRate to default
      this.playbackRateParam.linearRampToValueAtTime(
        this.defaultPlaybackRate,
        deltaTime + currentTime
      );
    } else if (
      this.status === "stopping" &&
      this.startAt + this.startingTime < currentTime
    ) {
      this.status = "stop";
    }
  }

  seekStart() {
    this.isSeeking = true;
  }

  seekEnd() {
    this.isSeeking = false;
  }
}
