








































































































































































































































































































































































































































































































































































































































































































































































































export default {
  name: "Burma"
}
