










import Vue from "vue";
import deaths from "../assets/deaths.json";

export default Vue.extend({
  name: "CoronaDeaths",
  data() {
    return {deaths: deaths.data}
  }
})
