

















import SlStyleButton from "@/components/UI/SlStyleButton.vue";
import SlStyleFader from "@/components/UI/SlStyleFader.vue";
import StatusLamp from "@/components/UI/StatusLamp.vue";
import Vue from "vue";

type SpeedControlData = {
  isReset: boolean;
  savedValue: number;
}

export default Vue.extend({
  name: "SpeedControl",
  components: {SlStyleButton, SlStyleFader, StatusLamp},
  props: {
    playbackRate: {
      type: Number,
      required: true
    },
    resetNoteNum: {
      type :Number,
      default: Infinity
    },
    speedNoteNum: {
      type :Number,
      default: Infinity
    }
  },
  methods: {
    changePitch(value: number) {
      this.savedValue = value;
      if (this.isReset) return;

      this.$emit("changePitch", value);
    },
    toggleReset() {
      if (this.isReset) {
        this.isReset = false;
        this.changePitch(this.savedValue);
      } else {
        this.savedValue = +(this.$refs.fader as InstanceType<typeof HTMLInputElement>).value;
        this.$emit("changePitch", 1.0);
        this.isReset = true;
      }
    }
  },
  data(): SpeedControlData {
    return {
      isReset: false,
      savedValue: 1.0
    }
  }
});
