





































import Vue, {Component} from "vue"
import ScratchableTrack from "./components/ScratchableTrack.vue";
import BLM from "./components/BLM.vue";
import Burma from "./components/Burma.vue";
import CoronaDeaths from "./components/CoronaDeaths.vue";
import Intro from "./components/Intro.vue";
import AccessDeny from "./components/AccessDeny.vue";
import BeatSelect, {LoadedBeat} from "@/components/UI/BeatSelect.vue";
import Mixer from "@/components/UI/Mixer.vue";
import WorkCaption from "./components/WorkCaption.vue";

import * as Tone from "tone";
import {TurntablePlayerStatus} from "@/scripts/TurntablePlayer";

import serverConfig from "../server/config.json";

import {UAParser} from "ua-parser-js";

// @ts-ignore
window.AudioContext = window.AudioContext || window.webkitAudioContext;

export type TrackInfo = {
  url: string;
  component: Component;
  scrollScale: number;
}

export const trackInfos = new Map<string, TrackInfo>([
  ["blm", {url: "music/blm.mp3", component: BLM, scrollScale: 3000}],
  ["covid", {url: "music/covid19.mp3", component: CoronaDeaths, scrollScale: 2000}],
  ["burma", {url: "music/burma.mp3", component: Burma, scrollScale: 500}]
]);

export type TrackData = {
  name: string;
  buffer: AudioBuffer;
  content: Component;
  scrollScale: number;
}

type AppData = {
  isSupported: boolean;
  context: AudioContext | undefined;
  buffer: AudioBuffer | undefined;
  isLoading: boolean;
  isStarted: boolean;
  beforeUpdatedAt: number;
  trackStatuses: Map<string, TurntablePlayerStatus>;
  trackRefNames: string[];
  leftTrack: TrackData | null;
  rightTrack: TrackData | null;
  beats: LoadedBeat[];
  volumeA: Tone.Volume;
  volumeB: Tone.Volume;
  volumeBeat: Tone.Volume;
  crossFade: Tone.CrossFade;
}

export default Vue.extend({
  name: 'App',
  components: {Intro, ScratchableTrack, AccessDeny, BeatSelect, Mixer, WorkCaption},
  async created() {
    const platform = new UAParser();

    if (platform) {
      const browserName = platform.getBrowser().name;
      const isBrowserSupported = browserName !== undefined ? ["Chrome", "Firefox", "Edge"].includes(browserName) : false;
      const osName = platform.getOS().name;
      const isOSSupported = osName !== undefined ? !["iOS"].includes(osName) : true;
      this.isSupported = isBrowserSupported && isOSSupported;
    }

    try {
      this.context = new AudioContext();

      await this.context.audioWorklet.addModule("buffer-play-processor.js");
    } catch (e) {
      alert('Web Audio API is not supported in this browser');
    }


    const urls = [
      "music/blm.mp3",
      "music/covid19.mp3",
      // "music/burma.mp3",
      "music/beat1.wav",
      "music/beat2.wav",
      "music/beat3.wav",
      "music/beat4.wav",
      "music/beat5.wav",
      "music/beat6.wav",
      "music/beat7.wav",
      "music/beat8.wav"
    ];


    const buffers = await Promise.all(urls.map(async url => {
      const fetchResponse = await fetch(url);
      return await (this.context || new AudioContext()).decodeAudioData(await fetchResponse.arrayBuffer());
    }));

    this.leftTrack = {
      name: "blm",
      buffer: buffers[0],
      content: BLM,
      scrollScale: 3000
    };

    this.rightTrack = {
      name: "covid",
      buffer: buffers[1],
      content: CoronaDeaths,
      scrollScale: 2000
    };

    const beatNoteNum = [43, 44, 57, 58, 75, 76, 89, 90];

    this.beats = buffers.slice(2).map((buffer, i) => {
      return {name: urls[i], buffer, midiNoteNum: beatNoteNum[i]};
    });

    this.isLoading = false;
  },
  methods: {
    start() {
      if (this.isLoading || this.isStarted) return;

      // initialize WebAudio & Tone
      if (!this.context) this.context = new AudioContext();

      this.context.createBufferSource().start(0);
      Tone.setContext(this.context);

      this.isStarted = true;


      if (serverConfig.isServerEnabled) {
        const ws = new WebSocket(`${serverConfig.ws.host}:${serverConfig.ws.port}`);
        ws.addEventListener("open", () => {
          console.log("open");
        });

        ws.addEventListener("message", e => {
          const data = JSON.parse(e.data);

          if (data.type === "position") {
            if (data.trackID === 1) {
              (this.$refs["leftTrack"] as InstanceType<typeof ScratchableTrack>).controlFromServer(data.speed);
            } else if (data.trackID === 2) {
              (this.$refs["rightTrack"] as InstanceType<typeof ScratchableTrack>).controlFromServer(data.speed);
            }
          }
        });
      }

      (this.$refs["leftTrack"] as InstanceType<typeof ScratchableTrack>).init();
      (this.$refs["rightTrack"] as InstanceType<typeof ScratchableTrack>).init();

      this.volumeA = new Tone.Volume();
      this.volumeB = new Tone.Volume();
      this.volumeBeat = new Tone.Volume();
      this.crossFade = new Tone.CrossFade(0.5);

      Tone.connect((this.$refs["leftTrack"] as InstanceType<typeof ScratchableTrack>).getProcessor(), this.volumeA);
      Tone.connect((this.$refs["rightTrack"] as InstanceType<typeof ScratchableTrack>).getProcessor(), this.volumeB);
      this.volumeA.connect(this.crossFade.a);
      this.volumeB.connect(this.crossFade.b);
      this.crossFade.toDestination();
      this.volumeBeat.toDestination();

      this.update();
    },
    update(): void {
      if (!this.context) {
        requestAnimationFrame(this.update.bind(this));
        return;
      }

      const currentTime = this.context.currentTime;
      const deltaTime = currentTime - this.beforeUpdatedAt;

      (this.$refs["leftTrack"] as InstanceType<typeof ScratchableTrack>).update(currentTime, deltaTime);
      (this.$refs["rightTrack"] as InstanceType<typeof ScratchableTrack>).update(currentTime, deltaTime);

      this.beforeUpdatedAt = currentTime;
      requestAnimationFrame(this.update.bind(this));
    },
    async updateTrack(isRight: boolean, trackName: string) {
      if (!trackInfos.has(trackName)) return;

      const trackInfo = trackInfos.get(trackName);

      if (!trackInfo) return;

      const fetchResponse = await fetch(trackInfo.url);
      const buffer = await (this.context || new AudioContext()).decodeAudioData(await fetchResponse.arrayBuffer());

      if (isRight) {
        this.rightTrack = {
          name: trackName,
          buffer: buffer,
          content: trackInfo.component,
          scrollScale: trackInfo.scrollScale
        };
      } else {
        this.leftTrack = {
          name: trackName,
          buffer: buffer,
          content: trackInfo.component,
          scrollScale: trackInfo.scrollScale
        };
      }
    }
  },
  data(): AppData {
    return {
      isSupported: false,
      context: undefined,
      buffer: undefined,
      isLoading: true,
      isStarted: false,
      beforeUpdatedAt: 0,
      trackRefNames: ["track1", "track2", "track3"],
      trackStatuses: new Map<string, TurntablePlayerStatus>(),
      leftTrack: null,
      rightTrack: null,
      beats: [],
      volumeA: new Tone.Volume(),
      volumeBeat: new Tone.Volume(),
      volumeB: new Tone.Volume(),
      crossFade: new Tone.CrossFade()
    };
  }

});
