







import Vue from "vue";

export default Vue.extend({
  name: "WipeEffect",
  props: {
    delay: {
      type: Number,
      default: 0
    }
  },
});
