

































import Vue from "vue";

type WorkCaptionData = {
  isAboutShown: boolean;
}

export default Vue.extend({
  name: "WorkCaption",
  methods: {
    toggleAbout(shown: boolean) {
      this.isAboutShown = shown;
    }
  },
  data(): WorkCaptionData {
    return {
      isAboutShown: false
    }
  }

});
