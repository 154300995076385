









import Vue, {PropType} from "vue";
import SlStyleFader from "@/components/UI/SlStyleFader.vue";
import {CrossFade} from "tone";

export default Vue.extend({
  name: "CrossFader",
  components: {SlStyleFader},
  props: {
    crossFade: {
      required: true,
      type: Object as PropType<CrossFade>
    },
    midiChannelNum: {
      type: Number,
      default: -1
    },
    midiNoteNum: {
      type: Number,
      default: Infinity
    }
  },
  methods: {
    changeFade(value: number) {
      this.crossFade.fade.setValueAtTime(value, 0);
    }
  }
});
