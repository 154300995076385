

















import Vue from "vue"
import WipeEffect from "./WipeEffect.vue";
import SlStyleButton from "@/components/UI/SlStyleButton.vue";

export default Vue.extend({
  name: 'Intro',
  components: {WipeEffect, SlStyleButton},
  props: {
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    start() {
      this.$emit("start");
    },
    loadFile(e: any) {
     console.log(e);
    }
  }
});
