import EventEmitter from "eventemitter3";

type MidiAccess = any;

class MidiManager {
  private midi: MidiAccess | null;
  eventEmitter: EventEmitter;

  constructor() {
    this.midi = null;

    // @ts-ignore
    if (navigator.requestMIDIAccess) {
      // @ts-ignore
      navigator.requestMIDIAccess().then(this.success.bind(this), this.failure.bind(this));
    }

    this.eventEmitter = new EventEmitter();
  }

  success(midi: MidiAccess) {
    this.midi = midi;

    for (const entry of this.midi.inputs) {
      const input = entry[1];
      input.onmidimessage = this.onMidiMessage.bind(this);
    }
  }

  failure() {
    console.warn("webmidi not supported");
  }

  private onMidiMessage(message: any) {
    this.eventEmitter.emit("data", message.data);
  }
}

export default new MidiManager();
