<template>
  <p class="text">First of all, I want to thank every single one of you for coming out. This is very important. This is very vital. Black lives have always mattered, we have always been important, we have always met suffering, we have always succeeded, regardless. And now is the time. I ain’t waiting. I ain’t waiting. I have been born in this country. I’m 28-years-old. Born and raised in London. And for a time, every black person understands and realizes the first time you are reminded that you were black. You remember. Every black person in here remembered when another person reminded you that you were black.

    "None of you out there, all those protesters on the other side, protesting against what we want to do, protesting against what we want to try and achieve. Darn you, because this is so vital.

    "I need you guys to understand. I need you guys to understand. I need you to understand how painful this (beep) is. I need you to understand how painful it is. To be reminded every day that your race means nothing. And that isn’t the case anymore. There is never a case anymore. We are going to try it today. We are a physical representation of our support for George Floyd. We are a physical representation in our support for Sandra Bland. We are a physical representation on our support for Trayvon Martin. We are a physical representation of our support for Stephen Lawrence, for Mark Duggan.

    "It is very, very important that we keep control to this moment and we make this as peaceful as possible. We make this as peaceful and as organized as possible. Because you know what guys, they want us to mess up. They want us to be disorganized, but not today. Not today. Not today. (beep).

    "This message is specifically for black men. Black men. Black men, black men, we need to take care of our black women. We need to care of them. They are us. They are us. They are our future. We cannot demonize our own. We are the pillars of the family. Imagine this, a nation that is set up with individual families that are thriving, that are healthy, that communicate, that raise their children in love. Have a better rate of becoming better human beings. And that’s what we need to create. Black men, it starts with you.

    "Hey, it’s bad man. We can’t be trust no more. We have to be better. You don’t understand. I’m speaking to you from my heart. Look, I don’t know if I’m going to have a career off that this.

    "Today is about innocent people who were halfway through that process. We don’t know what George Floyd could have achieved. We don’t know what Sandra Bland could have achieved, but today we’re going to make sure that that won’t be an alien thought to our young ones. I’m sure you all came today, you left your kids, and when you see your kids, they’re aimlessly playing. They don’t understand what’s going on. Today’s the day that we remind them that we are dedicated, and this is a lifelong dedication.

    "Guys, we don’t leave here and stop. We don’t leave here and stop. This is longevity. Some of you are artists. Some of you are bankers. Some of you are lawyers. Some of you own shop stores, you are important. Your individual power, your individual right is very, very important. We can all join together to make this a better world. We can all do it together to make this special. We can all join together.</p>
</template>

<script>
export default {
name: "BLM"
}
</script>

<style scoped>
.text {
  margin: auto;
  height: 400000px;
  font-size: 250px;
  font-weight: bold;
  max-width: 250px;
  /*text-orientation: sideways;*/
  letter-spacing: 120px;
  overflow-x: hidden;

  /*text-align: justify;*/
  /*-ms-writing-mode: tb-rl;*/
  writing-mode: vertical-rl;
}
</style>
