













import Vue, {PropType} from "vue";
import VolumeFader from "@/components/UI/VolumeFader.vue";
import CrossFader from "@/components/UI/CrossFader.vue";


import {CrossFade, Volume} from "tone";

export default Vue.extend({
  name: "Mixer",
  components: {VolumeFader, CrossFader},
  props: {
    volumeA: {
      required: true,
      type: Object as PropType<Volume>
    },
    volumeBeat: {
      required: true,
      type: Object as PropType<Volume>
    },
    volumeB: {
      required: true,
      type: Object as PropType<Volume>
    },
    crossFade: {
      required: true,
      type: Object as PropType<CrossFade>
    }
  }
});
