<template>
<section class="access-deny">
  <div class="text">
    <p>お使いのブラウザは対応していません。</p>
    <p>Chrome, Edge, Firefoxの最新版をご利用ください。</p>
    <a href="https://scrolling.73ch.work">https://scrolling.73ch.work</a>
  </div>
</section>
</template>

<script>
export default {
name: "AccessDeny"
}
</script>

<style scoped>
.access-deny {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  background-color: white;
  position: fixed;
;
}
.access-deny .text {
  width: 80vw;
  max-width: 400px;

}
</style>
