













import Vue, {PropType} from "vue";
import SlStyleButton from "@/components/UI/SlStyleButton.vue";
import SlStyleFader from "@/components/UI/SlStyleFader.vue";
import StatusLamp from "@/components/UI/StatusLamp.vue";

import {Volume, gainToDb, dbToGain} from "tone";

export default Vue.extend({
  name: "VolumeFader",
  components: {SlStyleButton, SlStyleFader, StatusLamp},
  props: {
    label: {
      default: "",
      type: String
    },
    toneVolume: {
      required: true,
      type: Object as PropType<Volume>
    },
    volumeNoteNum: {
      type: Number,
      default: Infinity
    },
    volumeChannelNum: {
      type: Number,
      default: -1
    },
    muteNoteNum: {
      type: Number,
      default: Infinity
    },
    muteChannelNum: {
      type: Number,
      default: -1
    },
    isFireOnlyUp: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    changeVolume(value: number) {
      this.savedValue = gainToDb(value);
      if (this.isMuted) return;
      this.toneVolume.volume.setValueAtTime(this.savedValue, 0);
    },
    toggleMute(newStatus: boolean) {
      if (this.isFireOnlyUp) {
        if (this.isMuted) {
          this.toneVolume.volume.setValueAtTime(this.savedValue, 0);
          this.isMuted = false;
        } else {
          this.isMuted = true;
          this.toneVolume.volume.setValueAtTime(gainToDb(0.), 0);
        }
      } else {
        this.isMuted = newStatus;
        this.toneVolume.volume.setValueAtTime(this.isMuted ? gainToDb(0.) : this.savedValue, 0);
      }
    },
  },
  data() {
    return {
      isMuted: false,
      savedValue: gainToDb(1.0)
    }
  },
  computed: {
    faderValue(): number {
      return dbToGain(this.isMuted ? this.savedValue : this.toneVolume.volume.value);
    }
  }
});
