






import Vue from "vue";
import {trackInfos} from "../../App.vue";

export default Vue.extend({
  name: "TrackSelect",
  props: {
    value: {
      required: true,
      type: String
    }
  },
  computed: {
    trackNames(): string[] {
      return Array.from(trackInfos.keys());
    }
  },
  methods: {
    trackSelected(e: InputEvent) {
      if (!(e.currentTarget instanceof HTMLSelectElement)) {
        return;
      }

      this.$emit('selected', e.currentTarget.value);
    }
  }
});
