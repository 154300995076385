import EventEmitter from "eventemitter3";

export interface WorkletMessage {
  type: string,
  content: any
}

export default class WorkletPlayer {
  isLoaded = false;

  context: AudioContext;

  processEventEmitter: EventEmitter;
  private playerProcessor: AudioWorkletNode;
  playbackRateParam: AudioParam;
  private sharedArrayBuffers: SharedArrayBuffer[];

  constructor(context: AudioContext, audioBuffer: AudioBuffer) {
    this.context = context;

    this.playerProcessor = new AudioWorkletNode(
      this.context,
      "buffer-play-processor"
    );
    this.processEventEmitter = new EventEmitter<string | symbol, any>();

    // buffer setup
    this.sharedArrayBuffers = [];
    this.setBuffer(audioBuffer);

    // initialize playbackParam
    const param = (<Map<string, AudioParam>>(
      this.playerProcessor.parameters
    )).get("playbackRate");
    if (!param)
      console.error(
        "buffer-play-processor has not parameter 'playbackRate' or worklet is not available."
      );
    this.playbackRateParam = param || new AudioParam();

    // current sampleの受け取り
    this.playerProcessor.port.onmessage = (ev) => {
      this.processEventEmitter.emit("position", ev.data);
    };
  }

  setBuffer(audioBuffer: AudioBuffer) {
    this.sharedArrayBuffers = [];

    for (let i = 0; i < audioBuffer.numberOfChannels; ++i) {

      this.sharedArrayBuffers.push(
        new SharedArrayBuffer(audioBuffer.getChannelData(i).byteLength)
      );

      const monoBuffer = new Float32Array(this.sharedArrayBuffers[i]);
      monoBuffer.set(audioBuffer.getChannelData(i), 0);
    }

    // send to buffer ref to worklet
    this.sendData({
      type: "buffer",
      content: this.sharedArrayBuffers
    });

    this.isLoaded = true;
  }

  sendData(data: Required<WorkletMessage>) {
    this.playerProcessor.port.postMessage(data);
  }

  getProcessor() {
    return this.playerProcessor;
  }
}
