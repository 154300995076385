









import Vue from "vue";
import midiManager from "@/scripts/MidiManager";

export default Vue.extend({
  name: "SlStyleFader",
  props: {
    isVertical: {
      default: false,
      type: Boolean
    },
    scaleImgSrc: {
      default: "",
      type: String
    },
    size: {
      type: Number,
      default: 200
    },
    value: {
      type: Number,
      default: 1.0
    },
    min: {
      type: Number,
      default: 0.0
    },
    max: {
      type: Number,
      default: 1.0
    },
    resolution: {
      type: Number,
      default: 256
    },
    midiChannelNum: {
      type: Number,
      default: -1
    },
    midiNoteNum: {
      type: Number,
      default: Infinity
    }
  },
  mounted() {
    midiManager.eventEmitter.on("data", data => {
      if (data[1] == this.midiNoteNum && (this.midiChannelNum < 0 || data[0] == this.midiChannelNum)) {
        const newValue = data[2] / 127 * this.resolution;
        (this.$refs.fader as HTMLInputElement).value = newValue.toString();
        this.emitChange(newValue);
      }
    })
  },
  methods: {
    change(e: InputEvent) {
      if (!(e.currentTarget instanceof HTMLInputElement)) {
        return;
      }

      this.emitChange(+e.currentTarget.value);
    },
    emitChange(value: number) {
      this.$emit("change", value / this.resolution * (this.max - this.min) + this.min);
    }
  },
  computed: {
    mappedValue() {
      return (this.value - this.min) / (this.max - this.min) * this.resolution;
    },
    faderContainerStyle() {
      if (this.isVertical) {
        return {
          display: "flex",
          width: `${this.size}px`,
          marginBottom: "calc(100% - 54px)",
          flexDirection: "row"
        }
      } else {
        return {
          display: "flex",
          flexDirection: "column"
        }
      }

    },
    scaleImgStyle() {
      if (this.isVertical) {
        return {
          position: "absolute",
          height: `${this.size}px`,
          width: "auto",
          transform: `translateX(calc(${this.size * 0.5}px - 100% - 27px - 2px))` // 半分移動、画像の横幅分、つまみの半分、マージン
        }
      } else {
        return {
          marginTop: "2px",
          width: "100%"
        }
      }

    }
  }
});
